<template>
  <div>
    <Header />
    <pageTitle txt="発送指示書" :backBtn="backBtn" />
    <div id="wrapper">
      <ul class="list">
        <li class="list-item" v-for="(day, key) in dayList" :key="key">
          <div
            class="list-item__data col-date"
            :class="{
              saturday: day.week == 6,
              holiday: day.week == 0 || day.is_holiday == 1
            }"
          >
            {{ day.format | changeDateObject | dateFormatShort }}
          </div>
          <div class="list-item__data col-btn">
            <router-link
              :to="{
                path: '/iseya/shipping/instructions/detail/all/',
                query: {
                  date: day.format
                }
              }"
            >
              <btnS class="edit" btnTxt="全店の詳細" color="positive1" />
            </router-link>
            <router-link
              :to="{
                path: '/iseya/shipping/instructions/detail/shop/',
                query: {
                  date: day.format
                }
              }"
              class="shop-btn"
            >
              <btnS class="edit" btnTxt="各店の詳細" color="positive1" />
            </router-link>
            <div class="csv-btn">
              <btnS
                class="print"
                btnTxt="送り状CSV<br>書き出し"
                color="neutral"
                @click="outputCSV(day.format)"
              />
            </div>
            <div
              to="/iseya/shipping/instructions/detail/?print"
              class="print-btn"
              @click="
                (print = !print), (overlay = !overlay), setPrintDate(day.format)
              "
            >
              <btnS class="print" btnTxt="印刷" color="primary" />
            </div>
          </div>
        </li>
      </ul>
      <!-- <div class="pager-wrap">
        <pager v-on:toNext="toNext" v-on:toPrev="toPrev" />
      </div> -->
    </div>

    <div id="overlay" :class="{ visible: overlay }">
      <div class="print-paper" v-if="print">
        <div class="print-inner">
          <div class="print-meta">
            <div class="meta-date">{{ printDate }}</div>
            <div class="meta-txt">出荷分</div>
            <div class="meta-label">
              {{ today | changeDateObject | dateFormatShort }}
              <span v-if="isDecision">確定</span>
              <span v-else>時点暫定</span>
            </div>
            <div class="meta-box">
              箱数
              <span class="meta-box__num">{{ allStoreBoxCount | comma }}</span>
              個
            </div>
          </div>
          <ul class="list">
            <li
              class="list-item"
              v-for="product in allStoreItem"
              :key="product.id"
            >
              <div class="list-item__data data-name">{{ product.name }}</div>
              <div class="list-item__data data-blk">
                <div class="data-quantity">
                  <span class="num">
                    {{ product.total_num | comma }}
                  </span>
                  <span class="unit">{{ product.unit }}</span>
                </div>
                <div class="data-vacuum">
                  <div class="vacuum-txt">内 真空</div>
                  <span class="num">
                    {{ product.bacuum_num | comma }}
                  </span>
                  <span class="unit">{{ product.unit }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="print-paper" v-for="row in this.rowNum" :key="row">
        <div class="print-inner">
          <div class="meta-blk">
            <div class="meta-date">{{ printDate }}</div>
            <div class="meta-txt">出荷分</div>
            <div class="meta-label">
              {{ today | changeDateObject | dateFormatShort }}
              <span v-if="isDecision">確定</span>
              <span v-else>時点暫定</span>
            </div>
          </div>

          <ul class="list-shop">
            <li class="list-head">
              <ul class="product">
                <li class="date"></li>
                <li class="box">箱数</li>
                <li class="vacuum"></li>
                <li
                  class="product-item"
                  v-for="product in items"
                  :key="product.id"
                >
                  {{ product.name }}
                </li>
              </ul>
            </li>
            <!-- <instructionsDetailShopItem
              v-for="shop in shops.slice(row * dataPerRow - 7, row  * dataPerRow)"
              :key="shop.id"
              :name=shop.name
              date="23"
              :day=0
              :orderNum=0
              dayName="月祝"
              time="pm"
            /> -->
            <instructionsDetailShopItem
              v-for="(shop, key) in stores"
              :key="key"
              :items="shop.items"
              :name="shop.store_name"
              date="23"
              :day="0"
              :orderNum="1"
              :boxNum="shop.total_box_num"
              dayName="月祝"
              :time="shop.send_timing"
            />
          </ul>
        </div>
      </div>

      <div id="fixedMenu" v-if="print">
        <div v-on:click="(print = !print), (overlay = !overlay)">
          <btnS btnTxt="戻る" color="back" />
        </div>
        <div v-on:click="(print = !print), (overlay = !overlay)">
          <btnL btnTxt="この内容で印刷する" color="primary" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;

#meta {
  width: 100%;
  height: 75px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  padding: 0 30px 0 15px;
  position: relative;
}

.shop-btn {
  margin-left: 10px;
}

.csv-btn {
  margin-left: 30px;
}

.print-btn {
  margin-left: 30px;
}

#wrapper {
  box-sizing: border-box;
  padding: 0 0 160px;
}

.list {
  box-sizing: border-box;
  @extend .f_body;
  display: flex;
  flex-wrap: wrap;
  width: 751px;
  margin: 0 auto 20px;
  border-left: solid 1px variables.$bg-gray-2;
}

.list-head {
  display: flex;
  height: 60px;
  font-size: 1.2rem;
  letter-spacing: 0.05em;
  box-sizing: border-box;
}

.list-item {
  display: flex;
  flex-wrap: wrap;
  height: 60px;
  position: relative;

  &:nth-of-type(even) {
    background: variables.$bg-gray-1;
  }
}

.list-item__data {
  box-sizing: border-box;
  padding: 0 15px;
  height: 100%;
  border-right: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
  display: flex;
  align-items: center;
  &.number {
    justify-content: flex-end;
  }
  &.negative {
    color: variables.$negative;
  }
  &.sunday,
  &.holiday {
    color: variables.$sunday;
  }
  &.saturday {
    color: variables.$saturday;
  }
}

.col-date {
  width: 150px;
}

.col-btn {
  width: 600px;
  justify-content: flex-end;
}

#overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(59, 64, 67, 0.9);
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s ease;
  overflow: scroll;
  padding-bottom: 120px;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}

.print-paper {
  width: 945px;
  height: 668px;
  background: #fff;
  box-sizing: border-box;
  padding: 30px;

  margin: 0 auto 30px;
  .print-meta,
  .list,
  .meta-blk,
  .list-shop {
    transform: scale(0.69);
    transform-origin: top left;
    font-size: 1rem;
  }
  .list-head,
  .list-item {
    font-size: 1rem;
    height: 30px;
  }
  .col-name {
    width: 170px;
  }
  .col-data-1 {
    width: 38px;
  }

  .col-data-2 {
    width: 60px;
  }

  .col-note {
    width: 90px;
    font-size: 0.8rem;
    line-height: 1.25;
  }
}

.print-inner {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .meta-blk {
    width: 100%;
    height: 75px;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    padding: 0 30px 0 15px;
    position: relative;
  }

  .meta-date {
    @extend .f_pageTtl;
    margin-right: 10px;
    &.sunday,
    &.holiday {
      color: variables.$sunday;
    }
    &.saturday {
      color: variables.$saturday;
    }
  }

  .meta-txt {
    @extend .f_pageTtl;
    margin-right: 20px;
  }

  .meta-label {
    font-size: 1.5rem;
    letter-spacing: 0.06em;
    box-sizing: border-box;
    padding: 0 15px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px variables.$txt-default;
  }

  .meta-box {
    @extend .f_pageTtl;
    margin-left: 70px;
  }

  .meta-box__num {
    font-size: 2rem;
    display: inline-block;
    margin: 0 4px 0 7px;
  }

  .list {
    box-sizing: border-box;
    @extend .f_body;
    display: flex;
    flex-wrap: wrap;
    width: 1280px;
    margin: 0 auto 20px;

    .list-item {
      width: 200px;
      height: 120px;
      position: relative;
      box-sizing: border-box;
      border: solid 1px variables.$bg-gray-2;
      margin: 0 15px 20px 0;
      &:nth-of-type(6n) {
        margin-right: 0;
      }
    }

    .data-name {
      @extend .f_body;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .data-blk {
      height: 88px;
      background: variables.$bg-gray-1;
      display: flex;
      padding: 10px 15px 20px;
      box-sizing: border-box;
      .num {
        font-size: 2.4rem;
        letter-spacing: 0.05em;
        margin-right: 2px;
        white-space: nowrap;
      }
      .unit {
        @extend .f_body;
        position: relative;
        top: -4px;
      }
    }
  }

  .list-shop {
    box-sizing: border-box;
    border-left: solid 1px variables.$bg-gray-2;
    //border-right: solid 1px variables.$bg-gray-2;
    @extend .f_body;
    display: flex;
    flex-wrap: wrap;
    min-width: 1281px;
    margin: 0 auto 20px;

    .list-head {
      width: 160px;
      min-width: 160px;
    }

    .date {
      height: 90px;
      box-sizing: border-box;
      border-right: solid 1px variables.$bg-gray-2;
      border-bottom: solid 1px variables.$bg-gray-2;
    }

    .box {
      height: 30px;
      border-right: solid 1px variables.$bg-gray-2;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      box-sizing: border-box;
      padding: 0 15px;
      @extend .f_body;
    }

    .vacuum {
      height: 30px;
      box-sizing: border-box;
      border-right: solid 1px variables.$bg-gray-2;
    }

    .product-item {
      border-right: solid 1px variables.$bg-gray-2;
      border-bottom: solid 1px variables.$bg-gray-2;
      box-sizing: border-box;
      padding: 0 15px;
      height: 60px;
      display: flex;
      align-items: center;
      &:nth-of-type(odd) {
        background: variables.$bg-gray-1;
      }
    }

    .list-head,
    .list-item {
      margin-bottom: 40px;
    }
  }

  .data-quantity {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 50%;
    height: 100%;
  }

  .data-vacuum {
    color: variables.$scrollbar-back;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-end;
    width: 50%;
  }

  .vacuum-txt {
    @extend .f_body;
    width: 100%;
    text-align: right;
  }
}

.print-meta {
  width: 100%;
  height: 75px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  padding: 0 30px 0 15px;
  position: relative;
}

.meta-date {
  @extend .f_pageTtl;
  margin-right: 10px;
  &.sunday,
  &.holiday {
    color: variables.$sunday;
  }
  &.saturday {
    color: variables.$saturday;
  }
}

.meta-txt {
  @extend .f_pageTtl;
  margin-right: 20px;
}

.meta-label {
  font-size: 1.5rem;
  letter-spacing: 0.06em;
  box-sizing: border-box;
  padding: 0 15px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px variables.$txt-default;
}

.meta-box {
  @extend .f_pageTtl;
  margin-left: 70px;
}

.meta-box__num {
  font-size: 2rem;
  display: inline-block;
  margin: 0 4px 0 7px;
}

.print-btn {
  margin-left: auto;
}

#fixedMenu {
  position: fixed;
  z-index: 2;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 89px;
  background: transparent;
  backdrop-filter: blur(5.3040900230407715px);
  --webkit-backdrop-filter: blur(5.3040900230407715px);
  background-color: rgba(255, 255, 255, 0.33);

  display: flex;
  justify-content: center;
  align-items: center;
  .buttonS {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(0, -50%);
  }
}

#add,
#edit {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialog {
  box-sizing: border-box;
  width: 800px;
  padding: 30px 50px 50px;
  background: #fff;
  margin: 0 auto 25px;
}

.dialog-ttl {
  @extend .f_pageTtl;
  margin-bottom: 15px;
  text-align: center;
}

.dialog-form {
  display: flex;
  flex-wrap: wrap;
}

.dialog-form__item {
  @extend .f_caption;
  margin-top: 35px;
  &.name {
    width: 220px;
  }
  &.tax {
    width: 160px;
  }
  &.price {
    width: 120px;
  }
  &.note {
    width: 400px;
  }
  &.name,
  &.tax {
    margin-right: 20px;
  }
  label {
    margin-bottom: 5px;
    display: block;
  }
  input,
  select {
    box-sizing: border-box;
    border: solid 1px variables.$bg-gray-3;
    border-radius: 3px;
    background: #fff;
    width: 100%;
    height: 28px;
    padding: 0 12px;
  }
  .select {
    position: relative;
    &:after {
      content: "";
      pointer-events: none;

      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: variables.$txt-default transparent transparent transparent;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0, -50%);
    }
  }
}

.overlay-btn {
  display: flex;
  justify-content: center;
  width: 800px;
  height: 60px;
  position: relative;
  margin: 0 auto;
  .buttonS {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
}
.pager-wrap {
  width: 900px;
  margin: 0 auto;
}
</style>
<style lang="scss">
.meta-calendar__input {
  color: rgba(255, 255, 255, 0);
}

@media print {
  @page {
    size: portrait;
    margin: 0;
  }
  #wrapper,
  #fixedMenu {
    display: none !important;
  }
  #print {
    width: 251mm;
    height: 172mm;
    page-break-after: always;

    .list {
      transform: scale(1);
    }
  }
}
</style>
<script>
import Header from "@/components/iseya/header";
import pageTitle from "@/components/iseya/pageTitle";
import btnS from "@/components/btn/btnS";
import btnL from "@/components/btn/btnL";
// import pager from "@/components/common/pager";
import instructionsDetailShopItem from "@/components/iseya/instructionsDetailShopItem";
import Global from "@/components/mixins/Global.vue";
import ApiClient from "@/module/api";

export default {
  name: "ShopHome",
  components: {
    Header,
    pageTitle,
    btnS,
    btnL,
    // pager,
    instructionsDetailShopItem
  },
  mixins: [Global],
  data: function() {
    return {
      backBtn: true,
      print: false,
      today: "",
      overlay: false,
      load: false,
      rowNum: 1,
      dataPerRow: 7,
      printDate: "",
      isDecision: false,
      weekday: "",
      dayList: [],
      allStoreItem: [],
      stores: [],
      items: [],
      allStoreBoxCount: 0
    };
  },
  async created() {
    const param = {};
    const api = new ApiClient();
    const result = await api.post("/iseya/transfer/date", param);
    this.dayList = result.list;
  },
  methods: {
    async setPrintDate(date) {
      this.printDate = date;

      this.today = new Date();
      const selectDate = new Date(this.printDate);
      this.isDecision = selectDate.getTime() <= this.today.getTime();

      // 全店情報
      let param = {
        date: this.printDate
      };
      let api = new ApiClient();
      let result = await api.post("/iseya/instruction/item", param);
      this.allStoreItem = result.list;
      this.allStoreBoxCount = result.box;

      // 店舗ごと
      param = {
        date: this.printDate
      };
      api = new ApiClient();
      result = await api.post("/iseya/instruction/shop", param);
      this.stores = result.list;
      this.items = result.items;
    },
    outputCSV(date) {
      window.location.href = "/api/iseya/output/csv?date=" + date;
    }
  }
};
</script>
